<template>

<v-container fluid class='pt-0 overflow-y-auto qms_settings_page'>

<v-card style='max-width:1200px;'>
  <v-card-title class='primary white--text text-h6'>
    <v-icon class='mr-2' dark>mdi-shield-account</v-icon>Authentication
  </v-card-title>
  <v-card-text>
    <v-list class='transparent'>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Change Password</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action>
          <ChangePassword>
            <template #button="{click}">
              <v-btn class='primary actionBtn' @click='click'>Change</v-btn>
            </template>
          </ChangePassword>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Two Factor Authentication</v-list-item-title>
          <v-list-item-subtitle>
            Improve account security by requiring an additional code to login.
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <Manage2FA>
            <template #button="{enabled,loading,click}">
              <v-btn class='primary actionBtn' @click='click' :loading="loading">{{enabled?'Disable':'Enable'}}</v-btn>
            </template>
          </Manage2FA>
        </v-list-item-action>
      </v-list-item>

    </v-list>
  </v-card-text>
</v-card>

  <p class="mt-10" hidden>
    This profile page is to show:
    <ul>
      <li>First &amp; Last Name</li>
      <li>Username (User permission level)</li>
      <li>Account Name (Account permission level)</li>
      <li>Any other user information stored</li>
      <li>Change password section</li>
    </ul>
    <br />
    Account permissions for debugging:
    <br><br>
    <table>
      <tr
        v-for='m in MODULES'
        v-bind:key='m.xid'
      >
        <td>{{m.title}}</td><td class='pl-10'>{{m.code}}</td>
      </tr>
    </table>
  </p>

</v-container>
</template>

<style lang="scss">
//@import "@/assets/common";
.actionBtn{
  min-width:150px !important;
}
</style>

<script>
import { mapGetters } from 'vuex';
import Manage2FA from '@/components/Profile/Manage2FA'
import ChangePassword from '@/components/Profile/ChangePassword'


export default {
  name: 'Profile',

  components: {
    Manage2FA, ChangePassword
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters('Auth', ['MODULES']),
  },

  methods:{
  },

  mounted() {
  },

};
</script>
