var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("button", null, null, {
    enabled: _vm.enabled,
    loading: _vm.loading,
    click: _vm.buttonClick
  }), _c('v-dialog', {
    attrs: {
      "max-width": "400"
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Enable 2FA")]), _c('v-card-text', {
    staticClass: "pt-5 text-center"
  }, [_vm._v(" Scan the code below using your preferred app."), _c('br'), _c('span', {
    staticClass: "text-caption font-italic font-weight"
  }, [_vm._v("eg. Authy, Google Authenticator")]), _c('v-img', {
    staticClass: "mx-auto",
    attrs: {
      "src": _vm.qr_svg,
      "max-width": "300"
    }
  }), _vm._v(" Or, "), _c('a', {
    on: {
      "click": _vm.viewKey
    }
  }, [_vm._v("View Key")]), _vm._v(" for manual entry. ")], 1), _c('v-card-actions', {
    staticClass: "grey lighten-3"
  }, [_c('v-btn', {
    staticClass: "primary",
    attrs: {
      "block": ""
    },
    on: {
      "click": _vm.verify_enable
    }
  }, [_vm._v("Verify & Enable 2FA")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }