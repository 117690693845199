<template>
  <div>
    <slot name="button" v-bind="{enabled, loading, click: buttonClick}"></slot>
    <v-dialog v-model='isOpen' max-width="400">
      <v-card>
        <v-card-title class="primary white--text">Enable 2FA</v-card-title>
        <v-card-text class="pt-5 text-center">
          Scan the code below using your preferred app.<br />
          <span class="text-caption font-italic font-weight">eg. Authy, Google Authenticator</span>
          <v-img :src='qr_svg' max-width="300" class="mx-auto" />
          Or, <a @click="viewKey">View Key</a> for manual entry.
        </v-card-text>
        <v-card-actions class="grey lighten-3">
            <v-btn block class="primary" @click="verify_enable">Verify & Enable 2FA</v-btn>
        </v-card-actions>
        
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
export default {
  name: '',

  components: {
  },

  data: () => ({
    enabled: null,
    loading: true,
    isOpen: false,
    qr_svg: null,
    key: null,
  }),

  computed: {
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['ALERT', 'PROMPT']),
    refresh(){
      this.API({ method: 'POST', endpoint: 'auth/totp/generate' })
      .then(response => {
        this.enabled = response.enabled;
        this.qr_svg = response.key_URI_SVG ?? null;
        this.key = response.key ?? null;
        this.loading = false;
      });
    },

    buttonClick(){
      if(this.enabled == false) this.isOpen = true;
      else this.verify_disable();
    },

    viewKey(){
      this.PROMPT({
        title: '2FA Private Key',
        message: 'Copy this key into your 2FA app, or password manager.',
        placeholder: this.key,
      });
    },

    async verify_enable(){
      let code = await this.PROMPT({ title: "Two Factor Authentication", message: "Please enter your 2FA code:" })
      await this.API({ method: 'POST', endpoint: 'auth/totp/enable', data: { code } })
      this.ALERT({ title: 'Two Factor Authentication', message: 'Two factor authentication has been enabled.' });
      this.isOpen = false;
      this.refresh();
    },

    async verify_disable(){
      let code = await this.PROMPT({ title: "Disable Two Factor Authentication", message: "Please enter your 2FA code:" })
      await this.API({ method: 'POST', endpoint: 'auth/totp/disable', data: { code } })
      this.ALERT({ title: 'Two Factor Authentication', message: 'Two factor authentication has been disabled.' });
      this.refresh();
    }

  },

  mounted() {
    this.refresh();
  },

};
</script>
