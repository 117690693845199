var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pt-0 overflow-y-auto qms_settings_page",
    attrs: {
      "fluid": ""
    }
  }, [_c('v-card', {
    staticStyle: {
      "max-width": "1200px"
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text text-h6"
  }, [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "dark": ""
    }
  }, [_vm._v("mdi-shield-account")]), _vm._v("Authentication ")], 1), _c('v-card-text', [_c('v-list', {
    staticClass: "transparent"
  }, [_c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Change Password")])], 1), _c('v-list-item-action', [_c('ChangePassword', {
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref) {
        var click = _ref.click;
        return [_c('v-btn', {
          staticClass: "primary actionBtn",
          on: {
            "click": click
          }
        }, [_vm._v("Change")])];
      }
    }])
  })], 1)], 1), _c('v-list-item', [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v("Two Factor Authentication")]), _c('v-list-item-subtitle', [_vm._v(" Improve account security by requiring an additional code to login. ")])], 1), _c('v-list-item-action', [_c('Manage2FA', {
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref2) {
        var enabled = _ref2.enabled,
          loading = _ref2.loading,
          click = _ref2.click;
        return [_c('v-btn', {
          staticClass: "primary actionBtn",
          attrs: {
            "loading": loading
          },
          on: {
            "click": click
          }
        }, [_vm._v(_vm._s(enabled ? 'Disable' : 'Enable'))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1), _c('p', {
    staticClass: "mt-10",
    attrs: {
      "hidden": ""
    }
  }, [_vm._v(" This profile page is to show: "), _c('ul', [_c('li', [_vm._v("First & Last Name")]), _c('li', [_vm._v("Username (User permission level)")]), _c('li', [_vm._v("Account Name (Account permission level)")]), _c('li', [_vm._v("Any other user information stored")]), _c('li', [_vm._v("Change password section")])]), _c('br'), _vm._v(" Account permissions for debugging: "), _c('br'), _c('br'), _c('table', _vm._l(_vm.MODULES, function (m) {
    return _c('tr', {
      key: m.xid
    }, [_c('td', [_vm._v(_vm._s(m.title))]), _c('td', {
      staticClass: "pl-10"
    }, [_vm._v(_vm._s(m.code))])]);
  }), 0)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }