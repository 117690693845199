<template>
  <div>
    <slot name="button" v-bind="{ click: buttonClick }"></slot>
    <v-dialog v-model='isOpen' max-width="400" persistent>
      <v-card :disabled='loading' :loading='loading'>
          <v-card-title class="primary white--text">Change Password</v-card-title>
          <v-card-text class="pt-5 text-center">
            <v-form @keyup.native.enter="update">
              <v-text-field
                v-model='newPassword' ref='newPassword' label='New Password'
                type='password' dense outlined hide-details class="pb-4"
                autofocus
              />
              <v-text-field
                v-model='confirmPassword' label='Confirm Password'
                type='password' dense outlined hide-details class="pb-4"
              />
            </v-form>
          </v-card-text>
          <v-card-actions class="grey lighten-3">
            <v-spacer></v-spacer>
            <v-btn class="primary" @click="update" :loading="loading">Change</v-btn>
            <v-btn class="secondary" @click="isOpen = false">Cancel</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
//@import "@/assets/common";
</style>

<script>
import { mapActions } from 'vuex'
export default {
  name: '',

  components: {
  },

  data: () => ({
    loading: false,
    isOpen: false,
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  }),

  computed: {
  },

  methods:{
    ...mapActions('API', ['API']),
    ...mapActions('UI', ['ALERT']),

    buttonClick(){
      this.newPassword = '';
      this.confirmPassword = '';
      this.isOpen = true;
    },

    update(){
      let { newPassword, confirmPassword } = this;
      this.API({
        method: 'POST',
        endpoint: 'core/auth/change_password',
        data: { newPassword, confirmPassword }
      })
      .then(() => {
        this.ALERT({ title: "Change Password", message: "Password has been updated." });
        this.isOpen = false;
      })
      .catch(() => {
        this.newPassword = '';
        this.confirmPassword = '';
        this.$nextTick(()=> { this.$refs['newPassword'].focus(); });
      });
      
    }

  },

  mounted() {
  },

};
</script>
