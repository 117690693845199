var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._t("button", null, null, {
    click: _vm.buttonClick
  }), _c('v-dialog', {
    attrs: {
      "max-width": "400",
      "persistent": ""
    },
    model: {
      value: _vm.isOpen,
      callback: function callback($$v) {
        _vm.isOpen = $$v;
      },
      expression: "isOpen"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.loading,
      "loading": _vm.loading
    }
  }, [_c('v-card-title', {
    staticClass: "primary white--text"
  }, [_vm._v("Change Password")]), _c('v-card-text', {
    staticClass: "pt-5 text-center"
  }, [_c('v-form', {
    nativeOn: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.update($event);
      }
    }
  }, [_c('v-text-field', {
    ref: "newPassword",
    staticClass: "pb-4",
    attrs: {
      "label": "New Password",
      "type": "password",
      "dense": "",
      "outlined": "",
      "hide-details": "",
      "autofocus": ""
    },
    model: {
      value: _vm.newPassword,
      callback: function callback($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  }), _c('v-text-field', {
    staticClass: "pb-4",
    attrs: {
      "label": "Confirm Password",
      "type": "password",
      "dense": "",
      "outlined": "",
      "hide-details": ""
    },
    model: {
      value: _vm.confirmPassword,
      callback: function callback($$v) {
        _vm.confirmPassword = $$v;
      },
      expression: "confirmPassword"
    }
  })], 1)], 1), _c('v-card-actions', {
    staticClass: "grey lighten-3"
  }, [_c('v-spacer'), _c('v-btn', {
    staticClass: "primary",
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "click": _vm.update
    }
  }, [_vm._v("Change")]), _c('v-btn', {
    staticClass: "secondary",
    on: {
      "click": function click($event) {
        _vm.isOpen = false;
      }
    }
  }, [_vm._v("Cancel")])], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }